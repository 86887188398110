ul {
  list-style-image: './assets/imgs/check.png';
}

@font-face {
  font-family: "drukwide";
  src: url("./assets/fonts/Druk Wide Bold Regular.ttf") format("truetype");
  font-weight: 700;
  font-style: "normal";
}

@font-face {
  font-family: "poppins";
  src: url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: "normal";
}

@font-face {
  font-family: "poppins";
  src: url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 500;
  font-style: "normal";
}

@font-face {
  font-family: "gotham-thin";
  src: url("./assets/fonts/Gotham-Light.otf") format("opentype");
  font-weight: 500;
  font-style: "normal";
}

@font-face {
  font-family: "gotham";
  src: url("./assets/fonts/Gotham-Black.otf") format("opentype");
  font-weight: 700;
  font-style: "normal";
}

body {
  background-color: #141414;
}

span {
  font-weight: 500;
}
